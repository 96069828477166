import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { getPostsAPI } from './api';

import Homepage from './pages/index'
import Post from './pages/posts'
import Logo from './components/Logo'

const RouterComp = (state) => {
  return <Router>
    <Route render={({ location }) => (
      <div>
        <TransitionGroup>
          <CSSTransition
            key={location.pathname}
            classNames='fade'
            timeout={400}
          >
            <Switch location={location}>
              <Route  exact path='/' render={(routeProps) => (
                <Homepage {...routeProps} {...state} />
              )} />
              <Route  path='/*' render={(routeProps) => (
                <Post {...routeProps} {...state} />
              )} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    )} />

    </Router>
}

const LoadingComp = ({ loaded = false }) => {
  return (
    <div className='loading-el'>
      <Logo className='loading-logo' />

      <style jsx global>{`
        .loading-el img {
          width: 100px;
          // opacity: 0;
          // animation: 0.4s ease-out loadin 1;
          // animation-delay: 0.3s;
          // animation-fill-mode: forwards;
        }

        @keyframes loadin {
          0%{opacity: 0}
          100%{opacity: 1}
        }

        @keyframes loadout {
          0%{opacity: 1}
          100%{opacity: 0}
        }
    `}</style>
      <style jsx>
        {`
        .loading-el {
          margin: 0; padding: 0;
          font-family: 'Helvetica';
          position: fixed;
          background-color: #fff;
          height: 100vh;
          width: 100vw;
          top: 0; left: 0;
          z-index: 9999;
          display: flex; flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          transition: 0.4s ease-out opacity, 0.4s ease-out visibility;
          opacity: ${loaded ? '0' : '1'};
          visibility: ${loaded ? 'hidden' : 'visible'};

        }

      `}
      </style>
    </div>
  )
}

class LoadingAnimation extends Component {
  state = {
    loadedDelayCompleted: false
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({ loadedDelayCompleted: true })
    }, 1000);
  }
  render () {
    return (
      <div>
        <LoadingComp loaded={this.state.loadedDelayCompleted} />
        <RouterComp {...this.props} />
      </div>
    )
  }
}

export default class App extends Component {
  state = {
    posts: [],
    homepage: undefined,
    loading: true
  }
  
  async componentDidMount() {
    const res = await getPostsAPI()

    document.addEventListener("touchstart", function () { }, true);
    this.setState({ posts: res.posts, homepage: res.homepage[0], loading: false })
  }

  render () {
    const { loading } = this.state

    if (loading) return <LoadingComp />
    else return <LoadingAnimation {...this.state} />
  }
}
